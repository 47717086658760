<template>
    <div>
        <div class="text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
            <slot></slot>
        </div>
        <div class="bg-white flex items-center justify-between border-gray-200">
            <div class="flex-1 flex items-center justify-between">
                <div>
                    <div class="relative z-0 inline-flex flex-wrap">
                        <a v-for="(v, i) in values" @click="toggle(v)" :key="i"
                           class="cursor-pointer mr-2 mb-2 relative inline-flex items-center px-4 py-2 whitespace-nowrap rounded-lg border-2 bg-white text-sm font-medium hover:bg-gray-50"
                           :class="{
                            'border-indigo-500': isActive(v),
                            'bg-red-50 border-red-200 text-gray-500': !isAvailable(i),
                            'text-gray-700 border-gray-300': isAvailable(i),
                        }">
                            {{ text[i] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MultiSelectSmall",

    props: {
        value: {
            type: Array,
        },
        values: {
            type: Array,
        },
        text: {
            type: Array,
        },
        quantity: {
            type: Array,
        },
    },

    data:(vm) => ({
        local: vm.value,
    }),

    methods: {
        isActive(value) {
            const index = this.local.indexOf(value);

            return index !== -1;
        },
        isAvailable(index) {
            return this.quantity[index] > 0;
        },
        toggle(value) {
            const index = this.local.indexOf(value);

            if (!this.isAvailable(this.values.indexOf(value))) {
                return;
            }

            if (index === -1) {
                this.local.push(value);
            } else {
                this.local.splice(index, 1);
            }

            this.$emit('input', this.local);
        }
    }
}
</script>

<style scoped>

</style>
