<template>
    <div v-show="items.length > 0">
        <div class="text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
            <slot>Catering</slot>
        </div>
        <div class="bg-white flex items-center justify-between border-gray-200">
            <div class="flex-1 flex items-center justify-between">
                <div>
                    <div class="relative z-0 inline-flex flex-wrap">
                        <a @click="disable"
                           class="cursor-pointer mr-2 mb-2 relative inline-flex items-center px-4 py-2 whitespace-nowrap rounded-lg border-2 bg-white text-sm font-medium hover:bg-gray-50"
                           :class="{
                            'border-indigo-500': value === null,
                        }">
                            Kein Catering
                        </a>
                        <a v-for="(v, i) in values" @click="select(i)"
                           :key="v"
                           class="cursor-pointer mr-2 mb-2 relative inline-flex items-center px-4 py-2 whitespace-nowrap rounded-lg border-2 bg-white text-sm font-medium hover:bg-gray-50"
                           :class="{
                            'border-indigo-500': value === v,
                        }">
                            {{ text[i] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CateringSelect",

    props: {
        value: {},
        items: {
            type: Array
        }
    },

    computed: {
        text() {
            return this.items.map(l => l.name);
        },
        values() {
            return this.items.map(l => l.id);
        },
    },

    methods: {
        select(index) {
            this.$emit('input', this.values[index]);
        },
        disable() {
            this.$emit('input', null);
        }
    }
}
</script>

<style scoped>

</style>
