<template>
    <card-full-invisible>
        <template slot="header">Buchung erstellen</template>

        <div class="grid grid-cols-12 gap-6 mb-8 p-4" v-show="page !== 'confirm'">
            <input-text type="date" v-model="date" class="mb-4 col-span-12">
                Datum
            </input-text>

            <time-select class="col-span-12"
                         v-model="time"
                         time_selection_mode="free"
            ></time-select>

            <div v-if="selectedRoom"
                 class="mb-4 col-span-12 sm:col-span-6">
                <div class="text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Raum</div>
                <div>
                    <a @click="selectedRoom = null"
                       class="cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800"
                    >
                        {{ selectedRoom.name }}
                        <button type="button"
                                class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700"
                                aria-label="">
                            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                            </svg>
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <div>
            <div v-show="page === 'overview'">
                <loading v-show="loading"></loading>

                <p class="text-gray-800 p-4" v-show="rooms.length > 0">{{ rooms.length }} passende Räume gefunden.</p>

                <p class="text-gray-800 p-4" v-show="rooms.length === 0">
                    Keine buchbaren Seminarräume gefunden.
                </p>

                <tailwind-list v-show="rooms.length > 0">
                    <tailwind-list-item v-for="room in rooms" :key="room.id" @click="selectedRoom = room">
                        Raum <span class="font-bold">{{ room.name }}</span>
                        <template slot="secondary">
                            <i class="fas fa-users mr-2"></i>{{ room.persons }} Personen
                        </template>
                    </tailwind-list-item>
                </tailwind-list>

            </div>

        </div>

        <div v-if="page === 'confirm'" class="p-4">

            <p class="mb-8">
                Soll der Seminarraum
                <span class="font-bold">{{ selectedRoom.name }}</span>
                am <span class="font-bold">{{ date | date }}</span> gebucht werden?
            </p>

            <room-layout-select class="mb-8"
                                v-model="roomLayout"
                                :items="selectedRoom.room_layouts"
            ></room-layout-select>

            <multi-select-small
                class="mb-8"
                v-show="selectedRoom.postable_equipment.length > 0"
                :text="selectedRoom.postable_equipment.map(e => e.name)"
                :values="selectedRoom.postable_equipment.map(e => e.id)"
                :quantity="selectedRoom.postable_equipment.map(e => e.quantity)"
                v-model="postableEquipment">Zubuchbares Equipment
            </multi-select-small>

            <catering-select class="mb-8"
                             v-model="catering"
                             :items="selectedRoom.caterings"
            ></catering-select>

            <loading v-show="loading"></loading>

            <div class="flex justify-between" v-show="!loading">
                <button-secondary @click="selectedRoom = null">
                    Zurück
                </button-secondary>
                <button-primary @click="create">
                    Konferenzraum buchen
                </button-primary>
            </div>

        </div>
    </card-full-invisible>
</template>

<script>
import ButtonPrimary from "../components/UI/ButtonPrimary";
import InputText from "../components/UI/InputText";
import TailwindList from "../components/UI/TailwindList";
import TailwindListItem from "../components/UI/TailwindListItem";
import CardFullInvisible from "../components/UI/CardFullInvisible";
import Loading from "@/components/Loading";
import TimeSelect from "@/components/TimeSelect.vue";
import ButtonSecondary from "@/components/UI/ButtonSecondary.vue";
import CateringSelect from "@/components/CateringSelect.vue";
import MultiSelectSmall from "@/components/UI/MultiSelectSmall.vue";
import RoomLayoutSelect from "@/components/RoomLayoutSelect.vue";

export default {
    name: "ConferenceRoomBooking",

    components: {
        RoomLayoutSelect,
        MultiSelectSmall,
        CateringSelect,
        ButtonSecondary,
        TimeSelect,
        Loading,
        CardFullInvisible,
        TailwindListItem,
        TailwindList,
        InputText,
        ButtonPrimary
    },

    data: () => ({
        office: null,
        loading: false,
        date: new Date(),
        rooms: [],
        selectedRoom: null,
        time: '6:00-7:00',

        equipment: [],
        selectedEquipment: 'Keine Auswahl',

        postableEquipment: [],
        roomLayout: null,
        catering: null,
        outlookStart: '',
        outlookEnd: '',
    }),

    filters: {
        date(val) {
            let [y, m, d] = val.split('-');
            return d + '.' + m + '.' + y;
        },
        time(val) {
            return val.substr(11, 5) + " Uhr";
        },
        datetime(val) {
            let [y, m, d] = val.substr(0, 10).split('-');
            let time = val.substr(11, 5);
            return `${d}.${m}.${y} ${time}`;
        }
    },

    watch: {
        date() {
            this.search();
        },
        start() {
            this.search();
        },
        end() {
            this.search();
        },
    },

    mounted() {
        this.readInitial();

        // register listener for changes
        // eslint-disable-next-line no-undef
        if (Office.context.mailbox) {
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, (args) => {
                args.start;
                args.end;
                this.page = 'overview';
                this.readTime(args.start, args.end);
            });
        }

        this.open();
    },

    computed: {
        isAppointment() {
            // eslint-disable-next-line no-undef
            if (!Office.context.mailbox) {
                return false;
            }
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'appointment';
        },
        isMessage() {
            // eslint-disable-next-line no-undef
            if (!Office) {
                return false;
            }
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'message';
        },
        page() {
            if (this.selectedRoom) {
                return 'confirm';
            }

            return 'overview';
        },
        start() {
            return `${this.date} ${this.time.split('-')[0]}`;
        },
        end() {
            return `${this.date} ${this.time.split('-')[1]}`;
        },
    },

    methods: {
        readInitial() {
            if (!this.isAppointment) {
                console.log("no appointment, no register!");
                return;
            }

            // eslint-disable-next-line no-undef
            if (Office.context.mailbox.item.start === undefined) {
                console.log("start is undefined, aborting!");
                return;
            }
            console.log('getAsync Start');
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.start.getAsync(
                asyncResult => {
                    console.log("got async!");
                    // eslint-disable-next-line no-undef
                    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        console.log(asyncResult.error.message);
                    } else {
                        // Successfully got the start time, display it, first in UTC and
                        // then convert the Date object to local time and display that.
                        console.log('The start time in UTC is: ' + asyncResult.value.toString());
                        console.log('The start time in local time is: ' + asyncResult.value.toLocaleString());
                    }
                    //this.registerAsync();
                });
            //}
        },
        open() {
            this.selectedRoom = null;
            this.date = (new Date()).toISOString().slice(0, 10);
        },
        search() {
            this.selectedRoom = null;
            this.loading = true;
            this.$http.get(`/conference_room_bookings?start=${this.start}&end=${this.end}`)
                .then(response => {
                    this.loading = false;
                    this.rooms = response.data.data;
                })
                .catch(error => {
                    this.loading = false;
                    this.$root.$emit('error', error);
                });
            this.setTimeOutlook();
        },
        setTimeOutlook() {
            if (!this.isAppointment) {
                return;
            }

            this.$root.$nextTick(() => {
                if (new Date(this.outlookStart).getTime() === new Date(this.start).getTime()
                    && new Date(this.outlookEnd).getTime() === new Date(this.end).getTime()) {
                    return;
                }

                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.start.setAsync(new Date(this.start));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.end.setAsync(new Date(this.end));
            })
        },
        toOutlook() {
            this.createAppointment();
            this.createMessage();
        },
        createAppointment() {
            if (!this.isAppointment) {
                return;
            }
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.start.setAsync(new Date(this.start));
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.end.setAsync(new Date(this.end));
            // eslint-disable-next-line no-undef
            //Office.context.mailbox.item.location.setAsync(this.selectedRoom.name);
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.enhancedLocation.addAsync([{
                id: this.selectedRoom.name + '@parkefficient.onmicrosoft.com',
                type: 'room',
            }]);
            //displayName: "Frankfurt"
            //emailAddress: "frankfurt@parkefficient.onmicrosoft.com"
            //locationIdentifier: {id: 'frankfurt@parkefficient.onmicrosoft.com', type: 'room'}
        },
        createMessage() {
            if (!this.isMessage) {
                return;
            }
            const start = this.$options.filters.datetime(this.start);
            const end = this.$options.filters.time(this.end);
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.body.prependAsync(
                `Seminarraum: ${this.selectedRoom.name}
                Zeitraum: ${start} - ${end}
                Personen: ${this.selectedRoom.persons}`
            );
        },
        create() {
            this.loading = true;
            this.$http.post('/conference_room_bookings', {
                start: this.start,
                end: this.end,
                conference_room_id: this.selectedRoom.id,
                postable_equipment: this.postableEquipment,
                room_layout: this.roomLayout,
                catering: this.catering,
            }).then(() => {
                this.toOutlook();
                this.$router.replace('/');
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('handleErrorResponse', error);
            })
        },
        readTime(start, end) {
            this.$root.$nextTick(() => {
                // update only if different!

                // 2022-01-22T09:30:00.000Z

                this.outlookStart = start;
                this.outlookEnd = end;

                const newDate = start.substr(0, 10);
                if (this.date !== newDate) {
                    this.date = newDate;
                }

                const a = new Date(start).getHours() + ':' + ('0' + new Date(start).getMinutes()).slice(-2);
                const b = new Date(end).getHours() + ':' + ('0' + new Date(end).getMinutes()).slice(-2);

                const newTime = a + '-' + b;
                if (newTime !== this.time) {
                    this.time = newTime; // 6:00-7:00
                }
            })
        }
    },
}
</script>

<style scoped>

</style>
