<template>
    <span class="inline-flex rounded-md shadow-sm w-full" @click="$emit('click')">
        <button id="button-secondary" type="button" class="justify-center inline-flex w-full items-center py-4 border border-gray-500 text-base leading-6 font-medium rounded-lg text-gray-500 bg-white hover:bg-gray-200 focus:outline-none focus:border-gray-600 focus:shadow-outline-indigo active:bg-gray-200 transition ease-in-out duration-150">
            <slot>BUTTON TEXT</slot>
        </button>
    </span>
</template>

<script>
export default {
    name: "ButtonSecondary"
}
</script>

<style scoped>

</style>
