<template>
    <div v-show="slots.length > 1">
        <label class="block text-sm font-medium text-gray-700 mb-2">
            <slot>
                Zeitraum
            </slot>
        </label>
        <div class="flex items-center justify-between">
            <div class="flex-1 flex items-center justify-between">
                <div>
                    <div class="relative z-0 inline-flex">
                        <a v-for="(v,i) in values" @click="local=v"
                           class="mb-4 mr-2 relative inline-flex items-center px-4 py-2 whitespace-nowrap rounded-lg border-2 border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                           :class="{'border-gray-500': local===v}">
                            {{ text[i] }}<br>
                            {{ time[i] }} Uhr
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectMenu from "../UI/SelectMenu";

export default {
    name: "SlotSelect",

    components: {SelectMenu},

    props: ['value'],

    computed: {
        slots() {
            return this.$store.state.group.slots;
        },
        text() {
            return this.slots.map(slot => `${slot.name}`);
        },
        time() {
            return this.slots.map(slot => `${slot.begin}-${slot.end}`);
        },
        values() {
            return this.slots.map(slot => `${slot.begin}-${slot.end}`);
        },
        local: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    }
}
</script>

<style scoped>

</style>
