<template>
    <div class="grid grid-cols-12 gap-6">

        <div class="col-span-6" v-if="time_selection_mode === 'free'">
            <select-menu :values="index.slice(0, -1)"
                         :text="timeValues.slice(0, -1)"
                         v-model.number="startIndex">
                Von
            </select-menu>
        </div>

        <div class="col-span-6" v-if="time_selection_mode === 'free'">
            <select-menu :values="index.slice(1)"
                         :text="timeValues.slice(1)"
                         v-model.number="endIndex">
                Bis
            </select-menu>
        </div>

        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start"
             v-if="time_selection_mode === 'slot'">
            <slot-select v-model="slot"></slot-select>
        </div>
    </div>
</template>

<script>
import SelectMenu from "./UI/SelectMenu";
import SlotSelect from "./reservations/SlotSelect";

export default {
    name: "TimeSelect",
    components: {SlotSelect, SelectMenu},
    props: {
        value: {},
        time_selection_mode: {
            type: String,
            default: 'free', // free or slot
        },
        startTime: {
            default: 6, // start of booking time
        },
        endTime: {
            default: 22, // end of booking time
        },
        hasHalfSteps: {
            default: true,
        },
    },

    data: () => ({
        startIndex: 0,
        endIndex: 1,
    }),

    created() {
        this.updateTime();
    },

    watch: {
        value() {
            const [start, end] = this.value.split('-'); // 6:30-7:00,

            // find start in timeValues
            this.startIndex = this.timeValues.findIndex(v => {
                return v === start;
            });

            this.endIndex = this.timeValues.findIndex(v => {
                return v === end;
            });
        },
        time_selection_mode() {
            this.updateTime();
        },
        startIndex(value) {
            if (this.endIndex <= value) {
                this.endIndex = value + 1;
            }
            this.$emit('input', this.time);
        },
        endIndex(value) {
            if (this.startIndex >= value) {
                this.startIndex = value - 1;
            }
            this.$emit('input', this.time);
        },
    },

    computed: {
        slots() {
            return this.$store.state.group.slots;
        },
        slot: {
            get() {
                // strip the minute values
                const [start, end] = this.value.split('-');
                return `${start.split(':')[0]}-${end.split(':')[0]}`;
            },
            set(value) {
                // add minute values!
                const [start, end] = value.split('-');
                this.$emit('input', `${start}:00-${end}:00`);
            },
        },
        time() {
            return this.timeValues[this.startIndex] + '-' + this.timeValues[this.endIndex];
        },
        timeValues() {
            if (this.hasHalfSteps) {
            // TODO hard coded 30 minute slices.... break it up?
                const length = (this.endTime - this.startTime) * 2 + 1;
                return Array.from({length}, (_, i) => i).map(i => {
                    return (Math.floor(i / 2) + this.startTime).toString() + ':' + (i % 2 ? '30' : '00');
                });
            }

            const length = (this.endTime - this.startTime) + 1;
            return Array.from({length}, (_, i) => i).map(i => {
                return (i + this.startTime).toString();
            });
        },
        index() {
            return [...this.timeValues.keys()];
        },
    },

    methods: {
        updateTime() {
            if (this.time_selection_mode === 'free') {
                this.$emit('input', this.time);
            } else {
                this.$emit('input', `${this.slots[0].begin}-${this.slots[0].end}`);
            }
        },
    },
}
</script>

<style scoped>

</style>
